@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue"), url("../src/assets/helvetica-neue-master/HelveticaNeue-Regular.otf") format('truetype');
}

body {
  margin: 0;
  font-family:"Helvetica Neue";
}

