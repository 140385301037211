body{
    overscroll-behavior: none;
}
.Home {
    display: flex;
}
.home-footer{
    display: none;
}
@media (max-width: 1080px) {
    .Home{
        flex-direction: column;
    }
    .home-footer{
        display: flex;
    }
}